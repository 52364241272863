import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, Flex, Heading, IconButton, Input, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiClient } from '../client/ApiClient';
import { ParticipantDto } from '../client/dtos/participant.dto';
import { TeamDto } from '../client/dtos/team.dto';
import PageHeading from '../components/Heading';

function EditTeam() {

    const [team, setTeam] = useState<TeamDto>();
    const [teamName, setTeamName] = useState<string>('');
    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');

    const { id } = useParams();

    const navigate = useNavigate();

    const fetchTeam = async () => {
        const res = await ApiClient.get<TeamDto>(`/teams/${id}`);
        if (res.status === 200)
            setTeam(res.data);
    }

    const addParticipant = async () => {
        const res = await ApiClient.post<ParticipantDto>('/participants', {
            firstname,
            lastname,
            team: id
        });
        if (res.status === 200) {
            setFirstname('');
            setLastname('');
            fetchTeam();
        }
    }

    const updateName = async () => {
        const res = await ApiClient.put<ParticipantDto>(`/teams/${id}`, {
            name: teamName
        });
        if (res.status === 200)
            fetchTeam();
    }

    const removeParticipant = (id: string) => async () => {
        const res = await ApiClient.delete<ParticipantDto>(`/participants/${id}`);
        if (res.status === 200)
            fetchTeam();
    }

    const deleteTeam = async () => {
        const res = await ApiClient.delete<ParticipantDto>(`/teams/${id}`);
        if (res.status === 200)
            navigate('/teams');
    }



    useEffect(() => {
        fetchTeam();
    }, [id]);

    useEffect(() => {
        if (team)
            setTeamName(team.name);
    }, [team?.name]);

    return (
        <>
            <PageHeading pageName={`Équipe: ${team?.name}`} previous="/teams" color="green" />
            <Box p="4" width='100%' alignItems='center'>
                <Input placeholder="Nom de l'équipe" colorScheme='green' value={teamName} onChange={e => setTeamName(e.target.value)} />
                <Button mt="2" colorScheme='green' onClick={updateName}>Mettre à jour le nom</Button>
                <Heading size="md" mt="10">Participants</Heading>
                <Stack width='100%' alignItems='center' gap='0'>
                    {team?.participants.map((participant) => (
                        <Flex key={participant.id} p="2" borderBottomWidth="1px" overflow="hidden" width='100%' justifyContent='space-between'>
                            <Box as="span" fontSize="lg">
                                {participant.firstname} {participant.lastname}
                            </Box>
                            <IconButton colorScheme='red' aria-label="Delete" icon={<DeleteIcon />} onClick={removeParticipant(participant.id)} />
                        </Flex>
                    ))}
                </Stack>
                <Divider my="4" />
                <Stack gap="2" width='100%' alignItems='center'>
                    <Input placeholder="Prénom" colorScheme='green' value={firstname} onChange={e => setFirstname(e.target.value)} />
                    <Input placeholder="Nom" colorScheme='green' value={lastname} onChange={e => setLastname(e.target.value)} />
                    <Button colorScheme='green' onClick={addParticipant}>Ajouter un participant</Button>
                </Stack>
                <Flex px="10" mt="5" >
                    <Button colorScheme='red' w="100%" onClick={deleteTeam}>Supprimer l'équipe</Button>
                </Flex>
            </Box>
        </>
    );
}

export default EditTeam;
