import axios, { AxiosResponse } from "axios";
import { toast } from "../components/Toast";

interface ApiResponse<T> {
    status: number;
    data: T;
}

export class ApiClient {

    private static client = axios.create({
        baseURL: "https://api.wefeel-epitech.fr",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    });

    private static resToResponse<T>(res: AxiosResponse): ApiResponse<T> {
        return {
            status: res.status,
            data: res.data
        }
    }
    private static async tryRequest<T>(r: () => Promise<AxiosResponse<T>>) {
        try {
            const res = await r();
            return this.resToResponse<T>(res);
        } catch (err: any) {
            toast({
                title: "Error",
                description: err.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return {
                status: err.status || 500,
                data: err.data || err.message || "Unknown error"
            }
        }
    }

    public static async get<T>(path: string, params?: any): Promise<ApiResponse<T>> {
        return this.tryRequest<T>(() => this.client.get(path, { params }));
    }

    public static async post<T>(path: string, data?: any): Promise<ApiResponse<T>> {
        return this.tryRequest<T>(() => this.client.post(path, data));
    }

    public static async put<T>(path: string, data?: any): Promise<ApiResponse<T>> {
        return this.tryRequest<T>(() => this.client.put(path, data));
    }

    public static async delete<T>(path: string): Promise<ApiResponse<T>> {
        return this.tryRequest<T>(() => this.client.delete(path));
    }
}