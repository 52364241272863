import { Button, Input, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiClient } from '../client/ApiClient';
import { TeamDto } from '../client/dtos/team.dto';
import PageHeading from '../components/Heading';

function CreateTeam() {

    const [name, setName] = useState<string>('');

    const navigate = useNavigate();

    const createTeam = async () => {
        const res = await ApiClient.post<TeamDto>('/teams', { name });
        if (res.status === 200)
            navigate(`/teams/view/${res.data.id}`);
    }

    return (
        <>
            <PageHeading pageName="Créer une équipe" previous="/teams" color="green" />
            <Stack p="4" width='100%' alignItems='center'>
                <Input placeholder="Nom de l'équipe" colorScheme='green' value={name} onChange={e => setName(e.target.value)} />
                <Button colorScheme='green' mt="2" onClick={createTeam}>Créer</Button>
            </Stack>
        </>
    );
}

export default CreateTeam;
