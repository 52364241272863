import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiClient } from '../client/ApiClient';
import { TeamDto } from '../client/dtos/team.dto';
import PageHeading from '../components/Heading';
import Select from 'react-select';

function ListElement({ children, dest }: { children: React.ReactNode, dest: string }) {
    const navigate = useNavigate();
    return (
        <Flex alignItems='center' gap="3" p="4" borderBottomWidth="1px" overflow="hidden" width='100%' cursor='pointer' onClick={() => navigate(dest)}>
            {children}
        </Flex>
    );
}

function TeamList({ teams }: { teams: TeamDto[] }) {
    return (
        <Stack width='100%' overflow='hidden' px='2' gap='0'>
            {teams.map((team) => (
                <ListElement key={team.id} dest={`/teams/view/${team.id}`}>
                    <Flex justify="space-between" width='100%'>
                        <Box>
                            <Box>
                                <Box as="span" fontSize="lg">
                                    {team.name}
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box as="span" fontSize="sm" color="gray.600">
                                {team.participants.length} membres
                            </Box>
                        </Box>
                    </Flex>
                </ListElement>
            ))}
            <ListElement dest='/teams/create'>
                <AddIcon />
                Ajouter une équipe
            </ListElement>
        </Stack>
    );
}


function Teams() {

    const [teams, setTeams] = useState<TeamDto[]>([]);

    useEffect(() => {
        const fetchTeams = async () => {
            const res = await ApiClient.get<TeamDto[]>('/teams');
            if (res.status === 200)
                setTeams(res.data.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })));
        }
        fetchTeams();
    }, []);


    return (
        <>
            <PageHeading pageName="Équipes" previous="/" color="green" />
            <TeamList teams={teams} />
        </>
    );
}

export default Teams;
