import { Box, Button, Divider, Flex, Heading, Input, Text, Textarea } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { ApiClient } from '../client/ApiClient';
import { TeamDto } from '../client/dtos/team.dto';
import PageHeading from '../components/Heading';
import { toast } from '../components/Toast';

const getParticipantTeam = (teams: TeamDto[], participantId: string) => {
    const team = teams.find(team => team.participants.find(participant => participant.id === participantId));
    return team?.id;
}

export interface Item {
    label: string;
    value: string;
}

function AddScore() {

    const [teams, setTeams] = useState<TeamDto[]>([]);
    const [selectedParticipant, setSelectedParticipant] = useState<Item>();
    const [selectedTeam, setSelectedTeam] = useState<Item>();

    const [comment, setComment] = useState<string>('');
    const [points, setPoints] = useState<string>('');

    const selectParticipantRef = useRef(null) as any;
    const selectTeamRef = useRef(null) as any;

    useEffect(() => {
        const fetchTeams = async () => {
            const res = await ApiClient.get<TeamDto[]>('/teams');
            if (res.status === 200)
                setTeams(res.data);
        }
        fetchTeams();
    }, []);

    const addScore = async () => {
        if (selectedTeam) {
            const res = await ApiClient.post('/entries', {
                team: selectedTeam.value,
                reason: comment,
                score: parseInt(points ?? '0') || 0
            });
            if (res.status === 200) {
                toast({
                    title: 'Points ajoutés',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                setComment('');
                setPoints('');
                setSelectedParticipant(undefined);
                setSelectedTeam(undefined);
                selectParticipantRef.current.clearValue();
                selectTeamRef.current.clearValue();
            }
        } else {
            toast({
                title: 'Veuillez sélectionner une équipe',
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
        }
    }


    const participantOptions: Item[] = teams.map(team => team.participants.map(p => ({
        label: `${p.firstname} ${p.lastname}`,
        value: p.id
    }))).flat().sort((a, b) => a.label.localeCompare(b.label));

    const teamOptions: Item[] = teams.map(team => ({
        label: team.name,
        value: team.id
    })).sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }));

    useEffect(() => {
        if (selectedParticipant) {
            const teamId = getParticipantTeam(teams, selectedParticipant.value);
            if (teamId)
                setSelectedTeam(teamOptions.find(team => team.value === teamId));
        }
    }, [selectedParticipant, teams]);

    useEffect(() => {
        if (selectedTeam && selectedParticipant) {
            if (getParticipantTeam(teams, selectedParticipant.value) !== selectedTeam.value) {
                setSelectedParticipant(undefined);
                selectParticipantRef.current.clearValue();
            }
        }
    }, [selectedTeam, teams]);

    const numberOfPoints = parseInt(points ?? '0') || 0;

    return (
        <>
            <PageHeading pageName="Ajouter des points" previous="/" color="blue" />
            <Box p="4" width='100%' >
                <Heading size="sm" mb="2">Participant</Heading>
                <Select
                    ref={selectParticipantRef}
                    placeholder="Chercher un participant"
                    options={participantOptions}
                    value={selectedParticipant}
                    onChange={p => setSelectedParticipant(p as Item)}
                />
                <Heading size="sm" my="2">Équipe</Heading>
                <Select
                    ref={selectTeamRef}
                    placeholder="Chercher une équipe"
                    options={teamOptions}
                    value={selectedTeam}
                    onChange={p => setSelectedTeam(p as Item)}
                />
                <Divider my="5" />
                <Textarea placeholder="Commentaire" resize='none' colorScheme='blue' value={comment} onChange={e => setComment(e.target.value)} />
                <Input type='text' mt='2' placeholder="Nombre de points" colorScheme='blue' value={points} onChange={e => setPoints(e.target.value)} />
                <Flex justifyContent='center' mt='2'>
                    {[10, 20, 30, 50].map((p, i) => (
                        <Button key={i} mr='2' colorScheme='blue' onClick={() => setPoints(p.toString())}>{p}</Button>
                    ))}
                </Flex>
                <Box bg='gray.100' mt='4' borderRadius='10' p="4">
                    L'équipe <b>{selectedTeam?.label}</b> a <Text display="inline" color={numberOfPoints < 0 ? 'red.500' : 'green.500'}>{numberOfPoints < 0 ? "perdu" : "remporté"} <b>{numberOfPoints < 0 ? -numberOfPoints : numberOfPoints}</b></Text> points: <b>{comment}</b>
                </Box>

                <Button mt="5" colorScheme='blue' onClick={addScore}>Ajouter</Button>
            </Box>
        </>
    );
}

export default AddScore;
