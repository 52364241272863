import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
import Home from './pages/Home';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Teams from './pages/Teams';
import { ToastContainer } from './components/Toast';
import CreateTeam from './pages/CreateTeam';
import EditTeam from './pages/EditTeam';
import AddScore from './pages/AddScore';
import ScoreHistory from './pages/ScoreHistory';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/teams',
    element: <Teams />,
  },
  {
    path: '/teams/create',
    element: <CreateTeam />,
  },
  {
    path: '/teams/view/:id',
    element: <EditTeam />,
  },
  {
    path: '/scores',
    element: <AddScore />,
  },
  {
    path: '/history',
    element: <ScoreHistory />,
  }
]);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <RouterProvider router={router} />
      <ToastContainer />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
