import { Box, Grid } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import PageHeading from '../components/Heading';

const HomeBox = ({ name, color, path }: { name: string, color: string, path: string }) => {
    const navigate = useNavigate();

    return (
        <Box
            borderRadius={15}
            bg={color}
            color="white"
            fontWeight="bold"
            pb='100%'
            textAlign="center"
            pos="relative"
            transition='opacity 0.1s ease-in-out'
            _active={{
                opacity: 0.3,
            }}
            userSelect='none'
            onClick={() => navigate(path)}
        >
            <Box pos="absolute" w="100%" h="100%" display="flex" justifyContent="center" alignItems="center" top={0} left={0}>
                {name}
            </Box>
        </Box>
    );
};

function Home() {
    return (
        <>
            <PageHeading pageName='Accueil' color='black' />
            <Grid templateColumns="repeat(2, 1fr)" gap={3} m={3}>
                <HomeBox name="Équipes" color="green.500" path="/teams" />
                <HomeBox name="Ajouter scores" color="blue.500" path="/scores" />
                <HomeBox name="Historique" color="red.500" path="/history" />
            </Grid>
        </>
    );
}

export default Home;
