import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Heading, IconButton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const PageHeading = ({ pageName, previous, color }: { pageName: string, previous?: string, color: string }) => {
    const navigate = useNavigate();
    return (
        <Box>
            <Flex gap={3} alignItems='center' px={5} py={3}>
                {previous && <IconButton aria-label='Retour'
                    onClick={() => navigate(previous)}
                    icon={<ArrowBackIcon />}
                    variant='outline'
                    colorScheme={color}
                />}
                <Heading as='h1' size='lg' color={`${color}.600`}>{pageName}</Heading>
            </Flex>
            <Divider />
        </Box>
    );
}

export default PageHeading;