import { Box, Flex, IconButton, Stack, Text } from '@chakra-ui/react';
import PageHeading from '../components/Heading';
import { EntryDto } from '../client/dtos/entry.dto';
import { useEffect, useState } from 'react';
import { ApiClient } from '../client/ApiClient';
import { DeleteIcon } from '@chakra-ui/icons';
import { TeamDto } from '../client/dtos/team.dto';
import Select from 'react-select';

function ListElement({ children }: { children: React.ReactNode }) {
    return (
        <Flex alignItems='center' gap="3" p="4" borderBottomWidth="1px" overflow="hidden" width='100%' cursor='pointer'>
            {children}
        </Flex>
    );
}

interface Item {
    label: string;
    value: string;
}

function ScoreHistory() {

    const [entries, setEntries] = useState<EntryDto[]>([]);

    const fetchEntries = async () => {
        const res = await ApiClient.get<EntryDto[]>(selectedTeam ? `/entries?teamId=${selectedTeam.value}` : '/entries');
        if (res.status === 200)
            setEntries(res.data);
    }

    const deleteEntry = (id: string) => async () => {
        const res = await ApiClient.delete<EntryDto>(`/entries/${id}`);
        if (res.status === 200)
            fetchEntries();
    }

    const [teams, setTeams] = useState<TeamDto[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<Item | null>(null);

    const fetchTeams = async () => {
        const res = await ApiClient.get<TeamDto[]>('/teams');
        if (res.status === 200)
            setTeams(res.data);
    }

    useEffect(() => {
        fetchTeams();
        fetchEntries();
    }, []);

    useEffect(() => {
        fetchEntries();
    }, [selectedTeam]);

    const DAYS = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']


    const teamOptions: Item[] = teams.map(team => ({
        label: team.name,
        value: team.id
    })).sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }));


    return (
        <>
            <PageHeading pageName="Historique des scores" previous="/" color="red" />
            <Box px='2'>
                <Select
                    isClearable
                    placeholder="Chercher une équipe"
                    options={teamOptions}
                    value={selectedTeam}
                    onChange={p => setSelectedTeam(p as Item | null)}
                />
            </Box>
            <Stack width='100%' overflow='hidden' px='2' gap='0'>
                {entries.map((entry) => (
                    <ListElement key={entry.id}>
                        <Box>
                            <span style={{ color: "#888" }}>{DAYS[new Date(entry.createdAt).getDay()]} {new Date(entry.createdAt).toLocaleTimeString('fr')} -</span> L'équipe <b>{entry.team.name}</b> a <Text display="inline" color={entry.score < 0 ? 'red.500' : 'green.500'}>{entry.score < 0 ? "perdu" : "remporté"} <b>{entry.score < 0 ? -entry.score : entry.score}</b></Text> points: <b>{entry.reason}</b>
                        </Box>
                        <IconButton aria-label="delete" icon={<DeleteIcon />} colorScheme='red' onClick={deleteEntry(entry.id)} />
                    </ListElement>
                ))}
            </Stack>
        </>
    );
}

export default ScoreHistory;
